@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Titillium Web", sans-serif !important;
  height: 100%;
  scroll-margin: 8rem;
}

.menu-content {
  font-family: "Titillium Web", sans-serif !important;
  border-right: 1px solid #e2e8f0 !important;
  height: calc(100vh - 8rem) !important;
  @apply !top-32 sm:!top-20;
}

@media only screen and (min-width: 640px) {
  .menu-content {
    height: calc(100vh - 5rem) !important;
  }
}

div[role="search"] {
  font-family: "Titillium Web", sans-serif !important;
  background: white !important;
  border: 1px solid #cbd5e1;
  border-radius: 8px;
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  position: relative;
}

div[role="search"] > svg {
  position: relative;
  left: 0;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}

div[role="search"] > div[data-role="search:results"] {
  margin-right: 16px;
}

div[role="search"] > svg > path {
  fill: #94a3b8 !important;
}

div[role="search"] > i {
  margin-left: 4px !important;
}

div[role="search"] > input {
  border: none;
  padding: 0;
  margin: 0;
  margin-left: 8px;
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

div[role="search"] > {
  fill: #94a3b8 !important;
}

label[role="menuitem"] {
  font-family: "Titillium Web";
}

div[class="sc-kZLDfD lTPmt"] {
  display: none;
}

div:not([role="search"]):has(> div[data-role="search:results"]) {
  top: 60px;
  min-width: 253px;
  position: absolute;
  display: flex;
  z-index: 50;
  @apply border rounded-lg border-slate-300 !overflow-y-scroll;
}

div[data-role="search:results"] {
  margin: 0;
  width: 100%;
  min-height: auto;
  background: white;
  padding: 0;
}

div[data-role="search:results"] > li {
  margin: 0;
  background: white !important;
}

div[data-role="search:results"] > li > * {
  padding: 12px 16px !important;
  @apply !bg-white hover:!bg-slate-200 !text-sm !leading-4 !font-normal;
}

div:has(> div[data-role="search:results"]) > .ps__rail-y {
  display: none;
}

h1,
h2,
h3,
h4 {
  font-family: "Titillium Web" !important;
}

.-depth2 {
  background: #e2e8f0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.-depth2:hover {
  background: rgba(203, 213, 225, 0.7) !important;
}

.operation-type {
  margin-top: 0 !important;
  margin-right: 8px !important;
  font-weight: 600 !important;
  font-size: 10px !important;
  line-height: 10px !important;
  width: 32px !important;
  font-family: "Titillium Web" !important;
}

.post {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 15px !important;
  background: #186faf !important;
  border-radius: 2px !important;
}

.get {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 15px !important;
  background: #2f8132 !important;
  border-radius: 2px !important;
  text-transform: uppercase;
}

.delete {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 15px !important;
  background: #cc3333 !important;
  border-radius: 2px !important;
  text-transform: uppercase;
}

.put {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 15px !important;
  background: #95507c !important;
  border-radius: 2px !important;
  text-transform: uppercase;
}

.patch {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 15px !important;
  border-radius: 2px !important;
  text-transform: uppercase;
}

ul {
  @apply !list-disc !pl-4;
}

ul li::marker {
  @apply !text-slate-400;
  font-family: "Titillium Web" !important;
}

ul[role="menu"] {
  @apply !pl-0;
}

ul[role="menu"] > li > ul {
  @apply !pl-0;
}

ul[role="menu"] > li > ul > li > ul {
  @apply !pl-0;
}

ol {
  @apply !list-decimal !pl-6;
}

.redoc-markdown {
  @apply !space-y-3;
}

a[download] {
  border-radius: 8px;
  border: 1px solid #cbd5e1;
  color: #2563eb;
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 12px;
}
