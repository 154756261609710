.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
  text-align: center;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.App {
  font-family: "Titillium Web", sans-serif !important;
  @apply h-full;
}

#root {
  @apply h-full;
}

a {
  cursor: pointer;
}

h3 {
  display: block;
  font-size: 1.17em;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  font-weight: bold;
}

h4 {
  display: block;
  -webkit-margin-before: 1.33em;
  -webkit-margin-after: 1.33em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  font-weight: bold;
  font-size: 14px;
}

pre {
  color: #ffffff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

code {
  word-break: inherit !important;
}

.version-dropdown-container #dropdown-menu-align-right-api-version {
  background-color: #2b3d50;
  border-color: #2b3d50;
}

.version-dropdown-container a.active {
  background-color: #ededed;
  color: #333333;
}

.version-dropdown-container a.dropdown-item {
  color: #333333;
  padding: 0.25rem 1.2rem;
}

.nested-table thead tr,
.nested-table tr:first-child th {
  border-top: 0;
}

.nested-table tr:last-child td {
  border-bottom: 0;
}

.nested-table tr td:first-child,
.nested-table tr th:first-child {
  border-left: 0;
}

.nested-table tr td:last-child,
.nested-table tr th:last-child {
  border-right: 0;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  width: 64px;
  height: 64px;
  margin-top: -32px;
  margin-left: -32px;
  border-radius: 50%;
  border: 6px solid #e2e8f0;
  border-top-color: #2563eb;
  animation: spinner 0.6s linear infinite;
}
